import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { NewGraduateToListView } from "@onn/common";
import React, { FC, memo } from "react";
import styled from "styled-components";

import { NewGraduateUseIcon } from "../NewGraduateUseIcon";

import { Loading, Paper, Typography } from "~/components/uiParts";

import { useEmployeeTags } from "~/hooks/employeeTag";

type Props = {
  newGraduateToListView: NewGraduateToListView;
};

export const NewGraduateSummaryPaper: FC<Props> = memo(function EmployeeSummary({
  newGraduateToListView,
}) {
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  if (isLoadingEmployeeTagsData) {
    return (
      <Box height="116px">
        <Loading size="small" />
      </Box>
    );
  } else {
    return (
      <StyledPaper>
        <Stack spacing="16px">
          <NewGraduateUseIcon
            newGraduate={newGraduateToListView}
            newGraduateToListView={newGraduateToListView}
            statusVisibilityType={"onlyInvitationStatus"}
            isDetailed
          />
          <StyledGrayStack spacing="16px">
            <Stack spacing="4px">
              <Typography variant="caption" color="textPrimary">
                タグ
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {newGraduateToListView.employeeTagIds.length === 0
                  ? "未設定"
                  : newGraduateToListView.employeeTagIds
                      .map((tagId) => {
                        const tag = employeeTagsData?.employeeTags?.find((t) => t.id === tagId);
                        return tag ? tag.name : null;
                      })
                      .join(", ")}
              </Typography>
            </Stack>
            <Stack spacing="4px">
              <Typography variant="caption" color="textPrimary">
                電話番号
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {!newGraduateToListView.phoneNumber ? "未設定" : newGraduateToListView.phoneNumber}
              </Typography>
            </Stack>
          </StyledGrayStack>
        </Stack>
      </StyledPaper>
    );
  }
});

const StyledPaper = styled(Paper)`
  width: 400px;
  padding: 16px;
`;

const StyledGrayStack = styled(Stack)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-radius: 8px;
  padding: 16px;
`;
