import { Box } from "@material-ui/core";
import {
  getDayDiffInJST,
  NewGraduateToDisplay,
  NewGraduateToListView,
  RecruitmentStatus,
} from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, DatePickerV2, Modal, Typography } from "~/components/uiParts";

import { useUpdateNewGraduateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus";
import { OnUpdateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus/useUpdateNewGraduateRecruitmentStatus";
import { useEmployeeInformation } from "~/hooks/employeeInformation";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplay;
  newGraduateToListView?: NewGraduateToListView;
  recruitmentStatus: RecruitmentStatus;
  onUpdateRecruitmentStatus?: OnUpdateRecruitmentStatus;
};

export const ChangeRecruitmentStatusModal: FC<Props> = ({
  open,
  onCancel,
  newGraduate,
  newGraduateToListView,
  recruitmentStatus,
  onUpdateRecruitmentStatus,
}) => {
  const { updateNewGraduateRecruitmentStatus } = useUpdateNewGraduateRecruitmentStatus({
    onUpdateRecruitmentStatus,
  });

  const { data: employeeInformation, isLoading: isLoadingEmployeeInformation } =
    useEmployeeInformation(newGraduate.id);
  const initOfferAcceptanceDeadline = employeeInformation?.value.offerAcceptanceDeadline;
  const [offerAcceptanceDeadline, setOfferAcceptanceDeadline] = useState<{
    value: Date | undefined;
    isChanged: boolean;
  }>({ value: initOfferAcceptanceDeadline, isChanged: false });
  const handleChangeOfferAcceptanceDeadline = (date: Date | null) => {
    if (date) {
      setOfferAcceptanceDeadline({
        value: date ?? undefined,
        isChanged:
          !initOfferAcceptanceDeadline || getDayDiffInJST(date, initOfferAcceptanceDeadline) !== 0,
      });
    }
  };

  const handleSubmit = useCallback(() => {
    updateNewGraduateRecruitmentStatus(
      newGraduate,
      recruitmentStatus.id,
      offerAcceptanceDeadline.isChanged ? offerAcceptanceDeadline.value : undefined,
      newGraduateToListView
    );
    onCancel();
  }, [
    newGraduate,
    newGraduateToListView,
    onCancel,
    recruitmentStatus.id,
    offerAcceptanceDeadline,
    updateNewGraduateRecruitmentStatus,
  ]);

  return (
    <Modal
      open={open}
      title="選考ステータス変更"
      titleHint="選考ステータスを変更すると、設定に基づいてイベント・タスクが候補者に配信されます。"
      content={
        <Box>
          <Box mb="10px">
            <Typography variant="body1" align="center">
              選考ステータス「{recruitmentStatus.label}」にしますか？
            </Typography>
          </Box>

          <Box>
            <Typography variant="body1" align="center">
              ※シナリオの途中での変更をする場合、トリガーされていないアクションは実行・配信されません
            </Typography>
          </Box>

          <Box
            display={recruitmentStatus.isJobOffer() ? "flex" : "none"}
            justifyContent="center"
            alignItems="center"
            mt="32px"
          >
            <Typography variant="body2" color="textSecondary">
              承諾期日
            </Typography>
            <Box p="8px 8px 8px 12px">
              <DatePickerV2
                placeholder="日程を選択"
                onChange={handleChangeOfferAcceptanceDeadline}
                value={offerAcceptanceDeadline.value ?? null}
                disabled={isLoadingEmployeeInformation || !recruitmentStatus.isJobOffer()}
              ></DatePickerV2>
            </Box>
          </Box>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            変更
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
