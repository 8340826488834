import { Box } from "@material-ui/core";
import { EmployeeInformationGroupWithFieldAndOptions } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "../../_share";

import { UploadFileStepContent } from "../../_share/UploadFileStepContent";

import { useHandleInputCsvFileWithValidation } from "../../_share/useHandleInputCsvFileWithValidation";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";
import { useGenerateSettingSampleCSV } from "./useGenerateSettingSampleCSV";

import { useHandleClickUploadButton } from "./useHandleClickUploadButton";

import { Modal, VerticalStepper } from "~/components/uiParts";
import { useBulkUpdateEmployeesInformationWithCsv } from "~/hooks/employee/useBulkUpdateEmployeesInformation";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  onUpdate?: () => void | Promise<void>;
  employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[];
};

export const UpdateEmployeeInformationWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onUpdate,
  employeeInformationGroups,
}) => {
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const { activeStep, handleNext } = useActiveStep();

  const { bulkUpdateEmployeesInformationWithCsv } = useBulkUpdateEmployeesInformationWithCsv();

  const { generateSampleCSV } = useGenerateSampleCSV();

  const { generateSettingSampleCSV, isLoading } = useGenerateSettingSampleCSV();

  const { handleInputFile, errorMessages, isCheckingCsv, fileLabel, inputFile } =
    useHandleInputCsvFileWithValidation({
      validate: bulkUpdateEmployeesInformationWithCsv,
      csvUploadErrorLabel: "候補者情報一括追加",
    });

  const { handleClickUploadButton, isUploading } = useHandleClickUploadButton({
    inputFile,
    closeModal: onCancel,
    onUpload: onUpdate,
  });

  const sampleCsv = useMemo(
    () => generateSampleCSV(employeeInformationGroups),
    [employeeInformationGroups, generateSampleCSV]
  );

  const isUploadButtonDisabled =
    isCheckingCsv || isUploading || errorMessages.length > 0 || !inputFile;
  const isUploadButtonLoading = isCheckingCsv || isUploading;

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            sampleFile={{
              csvData: sampleCsv,
              filename: "候補者情報一括更新_アップロード用サンプルファイル",
            }}
            // TODO: カスタム項目設定ファイルも一緒にDLできるようにする
            // https://app.clickup.com/t/86eqf6nby
            settingFile={{
              csvData: generateSettingSampleCSV(),
              filename: "候補者情報一括更新_設定値ファイル",
              isLoading,
            }}
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            errorMessage={errorMessages.join("\n")}
            fileLabel={fileLabel}
            onCancel={onCancel}
            handleOnClickUpload={handleClickUploadButton}
            isUploadButtonDisabled={isUploadButtonDisabled}
            isUploadButtonLoading={isUploadButtonLoading}
            filePickerProps={{
              onChange: handleInputFile,
              accepts: ["csv"],
              multiple: false,
            }}
          />
        );
    }
  }, [
    activeStep,
    errorMessages,
    fileLabel,
    generateSettingSampleCSV,
    handleClickUploadButton,
    handleInputFile,
    handleNext,
    isLoading,
    isUploadButtonDisabled,
    isUploadButtonLoading,
    onCancel,
    sampleCsv,
  ]);

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces)
          ? `候補者情報一括更新｜${currentSpace.name}`
          : "候補者情報一括更新"
      }
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const useActiveStep = () => {
  const [activeStep, setActiveStep] = useState(1);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return { activeStep, handleNext };
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
