import { v4 } from "uuid";

import { RichMenuCellSetting } from "../RichMenuCellSetting/RichMenuCellSetting";

import { IRegistrationRichMenu, registrationRichMenuSchema } from "./schema";

export class RegistrationRichMenu extends RichMenuCellSetting implements IRegistrationRichMenu {
  static readonly validator = registrationRichMenuSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly scenarioId: string;
  readonly spaceId: string;
  readonly externalRichMenuId: string;
  readonly imagePath: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(data: ExcludeMethods<RegistrationRichMenu>) {
    const parsedInit = RegistrationRichMenu.validator.parse(data);
    super(parsedInit);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.scenarioId = parsedInit.scenarioId;
    this.spaceId = parsedInit.spaceId;
    this.externalRichMenuId = parsedInit.externalRichMenuId;
    this.imagePath = parsedInit.imagePath;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<RegistrationRichMenu>, "id" | "createdAt" | "updatedAt">
  ): RegistrationRichMenu {
    return new RegistrationRichMenu({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(
    data: Omit<
      ExcludeMethods<RegistrationRichMenu>,
      "id" | "tenantId" | "scenarioId" | "spaceId" | "createdAt" | "updatedAt"
    >
  ): RegistrationRichMenu {
    return new RegistrationRichMenu({
      ...this,
      ...data,
      updatedAt: new Date(),
    });
  }
}
