import { Box } from "@mui/material";
import { intersection, without } from "lodash";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../RichMenuTabContext";
import { InputState } from "../useForm";

import { AsyncWarningPaper } from "./components/AsyncWarningPaper";

import { TargetRecruitmentStatusesSelector } from "./components/TargetRecruitmentStatusesSelector";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { Typography } from "~/components/uiParts";
import { useAllRichMenus } from "~/hooks/richMenu/useAllRichMenus";

export const AsyncSetting: FC<{ scenarioId: string }> = ({ scenarioId }) => {
  const { control } = useFormContext<InputState>();
  const {
    field: { value: selectedStatusIds },
  } = useController({ control, name: "asyncTargetRecruitmentStatusIds" });
  const { data: allRichMenus } = useAllRichMenus(scenarioId);
  const portalRichMenuRelations = allRichMenus?.portalRichMenusRelationWithRichMenu || [];
  const { selectedRecruitmentStatus } = useRichMenuTabContext();
  const { recruitmentStatusMap } = useRecruitmentStatusList();
  const recruitmentStatusIdsWhichHasRichMenu = portalRichMenuRelations.map(
    (menu) => menu.recruitmentStatusId
  );

  // NOTE: すでにリッチメニューが設定されているステータスを上書きする場合に警告を表示する
  const warningTargetStatusLabels = without(
    intersection(selectedStatusIds, recruitmentStatusIdsWhichHasRichMenu),
    selectedRecruitmentStatus?.id || ""
  ).map((id) => {
    return recruitmentStatusMap.get(id)?.label || "";
  });

  return (
    <Box display="flex" flexDirection={"column"} rowGap={"16px"} paddingBottom={"16px"}>
      <Box display="flex" alignItems="center" columnGap={"8px"}>
        <Typography bold variant="body2">
          設定のコピー
        </Typography>
      </Box>
      <Typography variant="body2">
        同じ設定を反映・上書きする選考ステータス選択してください。
      </Typography>
      {warningTargetStatusLabels.length > 0 && (
        <AsyncWarningPaper warningTargetStatusLabels={warningTargetStatusLabels} />
      )}
      <TargetRecruitmentStatusesSelector />
    </Box>
  );
};
