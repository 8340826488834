import React, { memo } from "react";

import { AnyInputCondition, AnyTarget } from "../../types/condition";

import { AssigneeConditionSelector } from "./condition-selectors/AssigneeConditionSelector";
import { EmployeePredictionConditionSelector } from "./condition-selectors/EmployeePredictionConditionSelector";
import { IdConditionSelector } from "./condition-selectors/IdConditionSelector";
import { NameConditionSelector } from "./condition-selectors/NameConditionSelector";
import { OfferAcceptanceDeadlineConditionSelector } from "./condition-selectors/OfferAcceptanceDeadlineConditionSelector";
import { OnnEventConditionSelector } from "./condition-selectors/OnnEventConditionSelector/OnnEventConditionSelector";
import { PrefectureConditionSelector } from "./condition-selectors/PrefectureConditionSelector";
import { RecruitmentStatusConditionSelector } from "./condition-selectors/RecruitmentStatusConditionSelector";
import { SchoolNameConditionSelector } from "./condition-selectors/SchoolNameConditionSelector";
import { SystemRegisteredDateRangeConditionSelector } from "./condition-selectors/SystemRegisteredDateRangeConditionSelector";
import { TagConditionSelector } from "./condition-selectors/TagConditionSelector";
import { UndefinedConditionSelector } from "./condition-selectors/UndefinedConditionSelector";

type Props = {
  index: number;
  condition: AnyInputCondition;
  onChangeTarget(index: number, target: AnyTarget): void;
  onChangeCondition(index: number, condition: AnyInputCondition): void;
};

/**
 * condition の target に応じて Selector を出し分けるコンポーネント
 */
export const ConditionSelector = memo(
  ({ index, condition, onChangeTarget, onChangeCondition }: Props): JSX.Element => {
    switch (condition.target) {
      case "assignee":
        return (
          <AssigneeConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "id":
        return (
          <IdConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "name":
        return (
          <NameConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "onnEvent":
        return (
          <OnnEventConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "tag":
        return (
          <TagConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "systemRegisteredDateRange":
        return (
          <SystemRegisteredDateRangeConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "prefecture":
        return (
          <PrefectureConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "schoolName":
        return (
          <SchoolNameConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "recruitmentStatus":
        return (
          <RecruitmentStatusConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "employeePrediction":
        return (
          <EmployeePredictionConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      case "offerAcceptanceDeadline":
        return (
          <OfferAcceptanceDeadlineConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
      default:
        return (
          <UndefinedConditionSelector
            index={index}
            condition={condition}
            onChangeTarget={onChangeTarget}
            onChangeCondition={onChangeCondition}
          />
        );
    }
  }
);
