import { Box } from "@material-ui/core";

import React, { FC } from "react";
import styled from "styled-components";

import {
  IconButton,
  TooltipWhenTextTruncated,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";
import { useScenarios } from "~/hooks/scenario/useScenarios";

type Props = {
  onClickRichMenuSetting: (scenarioId: string) => void;
};
// 複数シナリオが作成できるようになるときに作り直す
export const ScenarioListTable: FC<Props> = ({ onClickRichMenuSetting }) => {
  const widthOptions = ["90%", "10%"];
  const { currentUser } = useCurrentUser();
  const { data: scenarios = [], isLoading } = useScenarios();

  return (
    <VirtualizedTableV2
      rowHeight={90}
      widthOptions={widthOptions}
      isLoading={isLoading}
      headers={[
        {
          text: "タイトル",
        },
        {
          text: "",
        },
      ]}
      rows={scenarios}
      rowRenderer={({ key, index, style, rowData: scenario }) => {
        const contents = [<TitleCell key={key} name={scenario.name} />];
        if (currentUser.isAdmin()) {
          contents.push(
            <ScenarioManageMenu
              key={key}
              onClickRichMenuSetting={() => onClickRichMenuSetting(scenario.id)}
            />
          );
        }
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/scenarios/${scenario.id}`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const TitleCell: FC<{ name: string }> = ({ name }) => {
  return (
    <Box>
      <Box>
        <TooltipWhenTextTruncated text={name}>
          {(ref) => (
            <StyledTitle ref={ref} variant="body1" bold>
              {name}
            </StyledTitle>
          )}
        </TooltipWhenTextTruncated>
      </Box>
    </Box>
  );
};

const ScenarioManageMenu: FC<{
  onClickRichMenuSetting: () => void;
}> = ({ onClickRichMenuSetting }) => {
  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        {
          onClick: onClickRichMenuSetting,
          text: "未登録者用リッチメニュー設定",
        },
      ]}
    />
  );
};

const StyledTitle = styled(Typography)`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
