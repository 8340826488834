import {
  deleteObject,
  FirebaseStorage,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
  StorageError,
  uploadBytes,
  UploadResult,
} from "firebase/storage";

import { storage } from "~/config/firebase";
import { FileAPIAdapter as IFileAPIAdapter } from "~/service/usecases/file/interfaces/fileAPIAdapter";
import { captureException } from "~/util";
import { captureMessage } from "~/util/loggerUtil";

type BucketType = "public" | "private";

type Bucket = FirebaseStorage;
type UploadFileType = { path: string; file: File };
export type FileMetaData = { fullPath: string; name: string; size: number; url: string };

export class FileAPIAdapter implements IFileAPIAdapter {
  private readonly bucket: Bucket;

  static pathNormalizeForm = "NFC" as const;

  constructor({ bucketType = "public" }: { bucketType: BucketType }) {
    this.bucket = {
      public: storage.public,
      private: storage.private,
    }[bucketType];
  }

  getFileUrl(path: string): string {
    const storageRef = ref(this.bucket, path.normalize(FileAPIAdapter.pathNormalizeForm));
    return `https://storage.googleapis.com/${storageRef.bucket}/${storageRef.fullPath}`;
  }

  async upload({ path, file }: UploadFileType): Promise<UploadResult> {
    const res = await uploadBytes(
      ref(this.bucket, path.normalize(FileAPIAdapter.pathNormalizeForm)),
      file
    );

    return res;
  }

  async uploadFiles(uploadFiles: UploadFileType[]): Promise<UploadResult[]> {
    const results = await Promise.all(
      uploadFiles.map(async (uploadFile) => {
        return await this.upload({
          path: uploadFile.path.normalize(FileAPIAdapter.pathNormalizeForm),
          file: uploadFile.file,
        });
      })
    ).catch((e) => {
      captureException({ error: e, tags: { type: "fileAPIAdapter.uploadFiles" } });
      throw new Error(e);
    });

    // NOTE: アップロード不具合の調査目的でログを出力
    captureMessage({
      message: "ファイルのアップロードが完了しました",
      tags: { type: "fileAPIAdapter.uploadFiles" },
      extras: {
        payload: results,
      },
    });

    return results;
  }

  async fetchUrl({ path }: { path: string }): Promise<string | undefined> {
    return getDownloadURL(await ref(this.bucket, path)).catch((e: StorageError) => {
      // 参照先が存在しないときはエラーを出さない
      if (e.code === "storage/object-not-found") {
        return undefined;
      } else {
        throw e;
      }
    });
  }

  async fetchMetaDataByPaths({ paths }: { paths: string[] }): Promise<FileMetaData[]> {
    const promise = paths.map(async (path) => {
      return this.fetchMetaDataByPath({ path });
    });

    return await Promise.all(promise);
  }

  async fetchMetaDataByPath({ path }: { path: string }): Promise<FileMetaData> {
    return getMetadata(ref(this.bucket, path)).then(async (metadata) => {
      return {
        fullPath: metadata.fullPath,
        name: metadata.name,
        url: await getDownloadURL(ref(this.bucket, path)),
        size: metadata.size,
      };
    });
  }

  /**
   * NOTE: 管理者のみが利用可能
   * - セキュリティルールで入社者・候補者は list を許可していないため
   */
  async listMetadata({
    path,
  }: {
    path: string;
  }): Promise<{ fileName: string; url: string; path: string }[]> {
    const { items } = await listAll(ref(this.bucket, path));

    return await Promise.all(
      items.map(async (item) => ({
        fileName: item.name,
        url: await getDownloadURL(item),
        path: item.fullPath,
      }))
    );
  }

  async delete({ path }: { path: string }): Promise<void> {
    await deleteObject(ref(this.bucket, path));
  }
}
