import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { AnyTarget } from "../../../types/condition";

import { Button, Icon, Typography } from "~/components/uiParts";

const targetLabelMap = new Map<AnyTarget, string>([
  ["assignee", "担当者"],
  ["recruitmentStatus", "選考ステータス"],
  ["id", "候補者ID"],
  ["name", "氏名"],
  ["schoolName", "大学名"],
  ["prefecture", "住所"],
  ["tag", "タグ"],
  ["onnEvent", "イベント"],
  ["employeePrediction", "ヨミ"],
  ["offerAcceptanceDeadline", "内定承諾期日"],
  ["systemRegisteredDateRange", "システム登録日"],
]);

type Props = { target: AnyTarget; onChange(target: AnyTarget): void };
export const SelectTargetButton = ({ target, onChange }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box flex={1} display={"flex"}>
          <Typography variant="body2" noWrap>
            {targetLabelMap.get(target) ?? "対象を選択"}
          </Typography>
        </Box>
      </Button>
      <Menu
        key={"selectLogicTypeButton"}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {[...targetLabelMap.entries()].map(([target, label]) => (
          <Box key={target}>
            <MenuItem key={target} onClick={() => onChange(target)}>
              <Typography variant="body2">{label}</Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </>
  );
};
