import {
  NewGraduateToDisplay,
  NewGraduateToListView,
  RecruitmentStatusWithRelatedInfo,
} from "@onn/common";
import React, { ComponentProps, FC, useCallback } from "react";

import { ChangeRecruitmentStatusModal } from "../../employees";
import { RecruitmentStatusSelector } from "../RecruitmentStatusSelector";

import { useModal } from "~/hooks/modal";

type Props = {
  newGraduate: NewGraduateToDisplay;
  newGraduateToListView?: NewGraduateToListView;
  disabled?: boolean;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof ChangeRecruitmentStatusModal
  >["onUpdateRecruitmentStatus"];
};
export const ChangeRecruitmentStatusSelector: FC<Props> = ({
  newGraduate,
  newGraduateToListView,
  disabled,
  onUpdateRecruitmentStatus,
}) => {
  const { handleModal } = useModal();
  const updateRecruitmentStatus = useCallback(
    (recruitmentStatusToChange: RecruitmentStatusWithRelatedInfo) => {
      if (newGraduate.recruitmentStatusId === recruitmentStatusToChange.id) return;

      handleModal({
        name: "changeRecruitmentStatusModal",
        args: {
          newGraduate,
          recruitmentStatus: recruitmentStatusToChange,
          onUpdateRecruitmentStatus,
          newGraduateToListView,
        },
      });
    },
    [handleModal, newGraduate, newGraduateToListView, onUpdateRecruitmentStatus]
  );

  return (
    <RecruitmentStatusSelector
      selectedRecruitmentStatusId={newGraduate.recruitmentStatusId}
      disabled={disabled}
      onChange={updateRecruitmentStatus}
    />
  );
};
