import { Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { BriefingSessionEvent, NewInterviewEvent, OnnEvent } from "@onn/common";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { SelectMenu } from "../../employees";

import { SlotTypeInputPart } from "./components/SlotTypeInputPart";
import { useAnswerNewInterviewEventOnBehalfForm } from "./hooks/useAnswerInterviewEventOnBehalfForm";

import {
  Button,
  DatePickerV2,
  FormControlLabel,
  Icon,
  Loading,
  RadioButton,
  ScrollableBodyModal,
  SelectForm,
  TextFieldDeprecated,
  Tooltip,
  Typography,
  UserIconGroup,
} from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: NewInterviewEvent | BriefingSessionEvent;
  fixedOption?: {
    selectedEmployeeId?: string;
    selectedSlotDateId?: string;
    selectedBriefingSessionCategoryId?: string;
  };
  mode:
    | {
        type: "create";
      }
    | {
        type: "edit";
        onnEventSlotDateId: string;
        briefingSessionCategoryId?: string;
      };
  slotDefaultValueSetting: OnnEvent["slotDefaultValueSetting"];
  onSave?: () => void;
};

export const AnswerEventOnBehalfModal: FC<Props> = ({
  open,
  mode,
  onCancel,
  onnEvent,
  fixedOption,
  slotDefaultValueSetting,
  onSave,
}) => {
  const {
    form,
    handleSubmit,
    isSubmitting,
    isLoadingAdmins,
    isLoadingPlaces,
    isLoadingSelectableNewGraduates,
    isLoadingSelectableOnnEventSlotDatesForDisplay,
    isSubmitButtonDisabled,
    admins,
    adminsMap,
    isLoadingBriefingSessionCategories,
    briefingSessionCategories,
    briefingSessionCategoriesMap,
    selectedOnnEventSlotDate,
    onnEventPlaces,
    selectableNewGraduates,
    selectableNewGraduatesMap,
    selectedOnnEventSlotDateOptions,
    fromTimeItems,
    untilTimeItems,
    handleChangeCandidateDateFromTime,
    handleChangeCandidateDateUntilTime,
    selectBriefingSessionCategoryId,
    selectOnnEventSlotDate,
    changeSlotSelectionTypeExisted,
    changeSlotSelectionTypeNew,
  } = useAnswerNewInterviewEventOnBehalfForm({
    onnEvent,
    onSubmit: () => {
      onSave && onSave();
      onCancel();
    },
    fixedOption,
    slotDefaultValueSetting,
    defaultValues: {
      onnEventSlotDateId: mode.type === "edit" ? mode.onnEventSlotDateId : undefined,
      briefingSessionCategoryId: mode.type === "edit" ? mode.briefingSessionCategoryId : undefined,
    },
  });

  const { control, watch, trigger } = form;
  const Content = (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gridRowGap="32px">
        <Box display="flex" flexDirection="column" gridRowGap="16px">
          <Box display="flex" gridGap="12px" alignItems="center">
            <Typography variant="body2" bold>
              候補者
            </Typography>
            {!fixedOption?.selectedEmployeeId && (
              <Tooltip
                title="「現在配信対象になっていない候補者(辞退・不合格を除く)」と「配信対象のうち未回答の候補者」の選択が可能です。"
                placement="top-start"
              >
                <Icon icon="help" size="sm" color="grey" />
              </Tooltip>
            )}
          </Box>
          <Box>
            {fixedOption?.selectedEmployeeId ? (
              <>
                {isLoadingSelectableNewGraduates ? (
                  <Box width="240px">
                    <Loading size="small" />
                  </Box>
                ) : (
                  <Typography>
                    {selectableNewGraduatesMap.get(form.getValues("employeeId"))?.getName()}
                  </Typography>
                )}
              </>
            ) : (
              <Controller
                name="employeeId"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    {/* NOTE:SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント */}
                    {isLoadingSelectableNewGraduates ? (
                      <Box width="240px">
                        <Loading size="small" />
                      </Box>
                    ) : (
                      <Box display="flex" flexDirection="column" gridGap="4px">
                        <SelectMenu
                          selectedEmployee={
                            value ? selectableNewGraduatesMap.get(value) : undefined
                          }
                          selectEmployee={(employee) => onChange(employee?.id)}
                          employees={selectableNewGraduates}
                          isMultiple={false}
                        />
                        {fieldState.error && (
                          <Typography color="error" variant="caption">
                            {fieldState.error?.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
              />
            )}
          </Box>
        </Box>
        {watch("briefingSessionCategory.type") === "briefing_session" && (
          <Box display="flex" flexDirection="column" gridRowGap="12px">
            <Box display="flex" gridGap="12px" alignItems="center">
              <Typography variant="body2" bold>
                説明会
              </Typography>
            </Box>
            <Box display="flex" gridGap="12px" alignItems="center" width="240px">
              {isLoadingBriefingSessionCategories ? (
                <Loading size="small" />
              ) : fixedOption?.selectedBriefingSessionCategoryId ? (
                <Typography>
                  {
                    briefingSessionCategoriesMap.get(fixedOption?.selectedBriefingSessionCategoryId)
                      ?.title
                  }
                </Typography>
              ) : (
                <Controller
                  name="briefingSessionCategory.briefingSessionCategoryId"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <StyledSelectForm
                      {...field}
                      fullWidth
                      menuItems={briefingSessionCategories.map((briefingSessionCategory) => ({
                        value: briefingSessionCategory.id,
                        name: briefingSessionCategory.title,
                      }))}
                      selected={field.value || null}
                      onChange={(e) =>
                        typeof e.target.value === "string" &&
                        selectBriefingSessionCategoryId(e.target.value)
                      }
                      errorBorder={!!fieldState.error}
                      errorText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            </Box>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gridRowGap="16px">
          <Typography variant="body2" bold>
            予約枠
          </Typography>
          <Box display="flex" flexDirection="column" gridGap="8px">
            {fixedOption?.selectedSlotDateId ? (
              <Typography>{selectedOnnEventSlotDate?.getTermText()}</Typography>
            ) : (
              <>
                <Controller
                  name="slotSelectionType"
                  control={control}
                  render={({ field: { value } }) => (
                    <StyledFormControlLabel
                      value="existed"
                      control={
                        <StyledRadioButton
                          color="primary"
                          defaultColor={value === "existed" ? "primary" : "default"}
                          checked={value === "existed"}
                          onChange={() => changeSlotSelectionTypeExisted()}
                          disabled={!!fixedOption?.selectedSlotDateId}
                        />
                      }
                      label={<Typography variant="body2">既存の予約枠から選ぶ</Typography>}
                    />
                  )}
                />
                {watch("slotSelectionType") === "existed" && (
                  <Controller
                    name="selectedOnnEventSlotDateId"
                    control={control}
                    render={({ field, fieldState }) =>
                      isLoadingPlaces || isLoadingSelectableOnnEventSlotDatesForDisplay ? (
                        <Loading size="small" />
                      ) : (
                        <StyledSelectForm
                          {...field}
                          onChange={(e) =>
                            typeof e.target.value === "string" &&
                            selectOnnEventSlotDate(e.target.value)
                          }
                          fullWidth
                          menuItems={selectedOnnEventSlotDateOptions}
                          selected={field.value || null}
                          errorBorder={!!fieldState.error}
                          errorText={fieldState.error?.message}
                        />
                      )
                    }
                  />
                )}
              </>
            )}
          </Box>
          {!fixedOption?.selectedSlotDateId && (
            <Box display="flex" flexDirection="column" gridGap="8px">
              <Controller
                name="slotSelectionType"
                control={control}
                render={({ field: { value } }) => (
                  <StyledFormControlLabel
                    value="new"
                    control={
                      <StyledRadioButton
                        color="primary"
                        defaultColor={value === "new" ? "primary" : "default"}
                        checked={value === "new"}
                        onChange={() => changeSlotSelectionTypeNew()}
                      />
                    }
                    label={<Typography variant="body2">新しい予約枠を追加</Typography>}
                  />
                )}
              />
              {watch("slotSelectionType") === "new" && (
                <Box display="flex" minHeight="40px">
                  <Box mr="8px" width="160px">
                    <Controller
                      name="slotTimeInfo.slotDate"
                      defaultValue={new Date()}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        // TODO: デザインに揃える
                        <StyledDatePicker
                          fullWidth
                          placeholder="日程を選択"
                          value={value}
                          onChange={(date) => {
                            onChange(date);
                            trigger([
                              "slotTimeInfo.slotDate",
                              "slotTimeInfo.slotFromTimeString",
                              "slotTimeInfo.slotUntilString",
                            ]);
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box mr="8px">
                    <Controller
                      name="slotTimeInfo.slotFromTimeString"
                      control={control}
                      defaultValue="10:00"
                      render={({ field, fieldState }) => (
                        <StyledSelectForm
                          {...field}
                          icon="clock"
                          menuItems={fromTimeItems}
                          selected={field.value || null}
                          onChange={(e) => {
                            handleChangeCandidateDateFromTime(e);
                          }}
                          errorBorder={!!fieldState.error}
                          errorText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box mr="8px" mt="8px">
                    <Typography color="textSecondary">〜</Typography>
                  </Box>
                  <Box mr="8px">
                    <Controller
                      name="slotTimeInfo.slotUntilString"
                      control={control}
                      defaultValue="11:00"
                      render={({ field, fieldState }) => (
                        <StyledSelectForm
                          {...field}
                          icon="clock"
                          menuItems={untilTimeItems}
                          selected={field.value || null}
                          onChange={(e) => {
                            handleChangeCandidateDateUntilTime(e);
                          }}
                          errorBorder={!!fieldState.error}
                          errorText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box mr="16px">
                    <Controller
                      name="capacity"
                      control={control}
                      defaultValue="1"
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          freeSolo
                          disableClearable
                          options={[...Array(100)].map((_, i) => String(i + 1))}
                          onChange={(_, value) => {
                            field.onChange(value);
                            trigger("capacity");
                          }}
                          value={String(field.value)}
                          renderInput={(params) => (
                            <TextFieldDeprecated
                              {...params}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                trigger("capacity");
                              }}
                              label="定員"
                              value={String(field.value)}
                              variant="outlined"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {form.getFieldState("slotSelectionType").error && (
            <Box ml="8px">
              <Typography variant="caption" color="error">
                {`「予約枠」を選択してください。`}
                {/* TODO: zodスキーマでエラーメッセージを管理させる */}
              </Typography>
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gridRowGap="16px">
          <Box display="flex" flexDirection="column" gridRowGap="12px">
            <Box display="flex" gridGap="12px" alignItems="center">
              <Typography variant="body2" bold>
                {onnEvent.type === "briefing_session" ? "主担当" : "選考担当者"}
              </Typography>
              <Tooltip
                title="Onnの管理者アカウントを持っているメンバーのみ表示されます。"
                placement="top-start"
              >
                <Icon icon="help" size="sm" color="grey" />
              </Tooltip>
            </Box>
            <Box display="flex" gridGap="12px" alignItems="center">
              {fixedOption?.selectedSlotDateId || watch("slotSelectionType") === "existed" ? (
                <Typography>
                  {adminsMap.get(selectedOnnEventSlotDate?.assigneeId || "")?.getName() || "未設定"}
                </Typography>
              ) : (
                <Controller
                  name="assigneeId"
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    // NOTE: SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント
                    isLoadingAdmins ? (
                      <Box width="240px">
                        <Loading size="small" />
                      </Box>
                    ) : (
                      <SelectMenu
                        selectedEmployee={value ? adminsMap.get(value) : undefined}
                        selectEmployee={(employee) => onChange(employee?.id)}
                        employees={admins || []}
                        isMultiple={false}
                      />
                    )
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
        {onnEvent.type === "briefing_session" && (
          <Box>
            <Box display="flex" gridGap="12px" alignItems="center">
              <Typography variant="body2" bold>
                副担当者
              </Typography>
              <Tooltip
                title="Onnの管理者アカウントを持っているメンバーのみ表示されます。"
                placement="top-start"
              >
                <Icon icon="help" size="sm" color="grey" />
              </Tooltip>
            </Box>
            <Box mt="12px" width={"240px"}>
              {watch("slotSelectionType") === "existed" ? (
                <UserIconGroup
                  usersInfo={(admins || [])
                    .filter((v) => watch("subAssigneeIds")?.includes(v.id))
                    .map((v) => {
                      return {
                        username: v.getName() || "",
                        profileIconImageUrl: v.profileIconImageUrl,
                      };
                    })}
                  max={3}
                  tooltip
                  iconSize="small"
                />
              ) : (
                <Controller
                  name="subAssigneeIds"
                  control={form.control}
                  render={({ field: { onChange, value } }) =>
                    // NOTE: SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント
                    isLoadingAdmins ? (
                      <Loading size="small" />
                    ) : (
                      <SelectMenu
                        selectedEmployees={(admins || []).filter((v) => value?.includes(v.id))}
                        selectEmployees={(employees) => onChange(employees.map((v) => v.id))}
                        employees={admins || []}
                        isMultiple={true}
                      />
                    )
                  }
                />
              )}
            </Box>
          </Box>
        )}
        {!fixedOption?.selectedSlotDateId && (
          <SlotTypeInputPart
            form={form}
            onnEventPlaces={onnEventPlaces}
            isLoadingPlaces={isLoadingPlaces}
          />
        )}
      </Box>
    </form>
  );

  return (
    <ScrollableBodyModal
      open={open}
      title={mode.type === "create" ? "代理予約" : "編集"}
      content={Content}
      onCancel={onCancel}
      footer={
        <StyledButtonContainer gridColumnGap="16px">
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            isLoading={isSubmitting}
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            内容を保存
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    width: 160px;
    padding: 8px 12px 8px 12px;
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    max-width: 240px;
    min-width: 100px;
    height: 40px;
  }
`;

const StyledAutocomplete = styled(Autocomplete<string, undefined, true, true>)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6.5px 14px;
    height: 40px;
    width: 100px;
  }
  .MuiFormHelperText-root {
    width: 250px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;
