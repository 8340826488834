import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent";

import { NewGraduateToListView } from "../../../Employee";

export interface APISchemaNewGraduates {
  "/api/new-graduates/search": {
    POST: {
      body: z.infer<typeof searchNewGraduatesBodySchema>["body"];
      response: {
        newGraduates: ExcludeMethods<NewGraduateToListView>[];
        nextCursor: string | null;
      };
    };
  };
  "/api/new-graduates/search/count": {
    POST: {
      body: z.infer<typeof searchNewGraduateCountBodySchema>["body"];
      response: {
        total: number;
      };
    };
  };
  "/api/new-graduates/search/ids": {
    POST: {
      body: z.infer<typeof searchNewGraduateIdsBodySchema>["body"];
      response: {
        newGraduateIds: string[];
        total: number;
      };
    };
  };
}

export const assigneeConditionSchema = z.object({
  target: z.literal("assignee"),
  assigneeIds: z.array(z.string()).min(1),
});
export const idConditionSchema = z.object({
  target: z.literal("id"),
  searchString: z.string().trim().min(1), // 候補者IDを区切り文字(半角・全角スペース, ",", "、")で区切ったもの
});
export const nameConditionSchema = z.object({
  target: z.literal("name"),
  searchString: z.string().trim().min(1), // 候補者氏名を区切り文字(半角・全角スペース, ",", "、")で区切ったもの (カナ・漢字可)
});
export const onnEventConditionSchema = z.object({
  type: onnEventSchema.shape.type,
  target: z.literal("onnEvent"),
  eventId: z.string(),
  candidateDateIds: z.array(z.string()).min(1).optional(),
  slotIds: z.array(z.string()).min(1).optional(),
  eventFormatTypes: z
    .array(z.enum(["online", "offline"]))
    .min(1)
    .optional(),
  attendanceStatuses: z
    .array(
      z.enum([
        "not_deriver",
        "before_answer",
        "after_answer",
        "registered_attendance",
        "absent_attendance",
      ])
    )
    .min(1)
    .optional(),
});
export const tagConditionSchema = z.object({
  target: z.literal("tag"),
  tagIds: z.array(z.string()).min(1),
  inclusion: z.enum(["include", "exclude"]).optional(),
});
export const systemRegisteredDateRangeConditionSchema = z.object({
  target: z.literal("systemRegisteredDateRange"),
  fromDate: z.date().optional(),
  untilDate: z.date().optional(),
});
export const prefectureConditionSchema = z.object({
  target: z.literal("prefecture"),
  prefectures: z.array(z.string()).min(1),
  types: z.array(z.string()).min(1),
});
export const schoolNameConditionSchema = z.object({
  target: z.literal("schoolName"),
  searchString: z.string().trim().min(1), // 大学名を区切り文字(半角・全角スペース, ",", "、")で区切ったもの (カナ・漢字可)
});
export const recruitmentStatusConditionSchema = z.object({
  target: z.literal("recruitmentStatus"),
  statusIds: z.array(z.string()).min(1),
});
export const employeePredictionConditionSchema = z.object({
  target: z.literal("employeePrediction"),
  predictionIds: z.array(z.string()).min(1),
});
export const offerAcceptanceDeadlineConditionSchema = z.object({
  target: z.literal("offerAcceptanceDeadline"),
  fromDate: z.date().optional(),
  untilDate: z.date().optional(),
});
export const searchNewGraduateConditionSchema = z.union([
  assigneeConditionSchema,
  idConditionSchema,
  nameConditionSchema,
  onnEventConditionSchema,
  tagConditionSchema,
  systemRegisteredDateRangeConditionSchema,
  prefectureConditionSchema,
  schoolNameConditionSchema,
  recruitmentStatusConditionSchema,
  employeePredictionConditionSchema,
  offerAcceptanceDeadlineConditionSchema,
]);
const baseSearchNewGraduatesBodySchema = z.object({
  body: z.object({
    type: z.enum(["AND", "OR"]),
    conditions: z.array(searchNewGraduateConditionSchema),
    keywordsString: z.string().optional(),
  }),
});
export const searchNewGraduatesBodySchema = z.object({
  body: baseSearchNewGraduatesBodySchema.shape.body.extend({
    cursor: z.string().nullable(),
  }),
});
export const searchNewGraduateIdsBodySchema = baseSearchNewGraduatesBodySchema;
export const searchNewGraduateCountBodySchema = baseSearchNewGraduatesBodySchema;
